<template>
    <div>
        <el-form :inline="true" size="small" :model="form">
            <el-form-item>
                <el-input
                    style="width: 300px"
                    prefix-icon="el-icon-search" v-model.trim="form.keyword" clearable :placeholder="$i18n.t('deviceLog.text5')"
                    @clear="getMsg(1)"
                    @keyup.enter.native="getMsg(1)" @blur="getMsg(1)"></el-input>
            </el-form-item>

            <el-form-item>
                <el-date-picker
                    v-model="form.time"
                    type="datetimerange"
                    clearable
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @change="timeChange"
                    :range-separator="$i18n.t('deviceLog.text6')"
                    :start-placeholder="$i18n.t('deviceLog.text7')"
                    :end-placeholder="$i18n.t('deviceLog.text8')">
                </el-date-picker>
            </el-form-item>

            <el-form-item>
                <el-select v-model="form.deliveryType" @change="getMsg(1)" :popper-append-to-body="false" clearable :placeholder="$i18n.t('deviceLog.text9')">
                    <el-option
                        v-for="item in $message.deliveryTypeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>

        <el-table
            ref="logTable"
            class="log_table"
            :data="logMsg.list"
            :height="tableHeight"
            :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
            :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
            v-loading="loading"
            style="width: 100%;">

            <el-table-column
                min-width="110"
                :label="$i18n.t('deviceLog.text10')">
                <template slot-scope="scope">
                    {{ (page - 1) * 10 + (scope.$index + 1) }}
                </template>
            </el-table-column>
            <el-table-column
                min-width="35"
                :label="$i18n.t('deviceLog.text11')">
                <template slot-scope="scope">
                    {{ $config.format(parseInt(scope.row.eventTime), true) }}
                </template>
            </el-table-column>
            <el-table-column
                min-width="30"
                :label="$i18n.t('deviceLog.text12')">
                <template slot-scope="scope">
                    {{$config.getMsgItemUtil($message.deliveryTypeList,scope.row.deliveryType,'id','name') }}
                </template>
            </el-table-column>
            <el-table-column
                :label="$i18n.t('deviceLog.text13')">
                <template slot-scope="scope">
                    {{ JSON.stringify(scope.row) }}
                </template>
            </el-table-column>

        </el-table>

        <el-pagination
            background
            :current-page="page"
            @current-change="handleCurrentChange"
            :page-size="10"
            layout=" prev, pager, next"
            style="margin-top: 10px;text-align: right;"
            :total="parseInt(logMsg.totalRecords)">
        </el-pagination>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {

    name: "communicationLog",

    data() {

        return {

            tableHeight: 300,

            loading: false,

            logMsg: {
                list: [],
                totalRecords: 0
            },

            page: 1,

            form: {
                //设备ID
                keyword: this.$route.query.deviceId,
                //时间区间
                time: "",
                //开始时间
                startTime: "",
                //结束时间
                endTime: "",
                //日志类型
                deliveryType: ""
            }

        }

    },

    mounted() {
        this.$route.query.deviceId && this.getMsg()
    },

    methods: {

        ...mapActions('device', ['queryMsgLog']),

        getMsg(page) {
            if (page) this.page = page;
            if (this.form.keyword === "") {
                this.logMsg = {
                    list: [],
                    totalRecords: 0
                }
                return;
            }
            this.loading = true;
            this.queryMsgLog({
                pageSize: 10,
                currPage: this.page,
                deviceId: this.form.keyword,
                deliveryType: this.form.deliveryType,
                logType: 1,
                startCreateTime: this.form.startTime,
                endCreateTime: this.form.endTime
            }).then(res => {
                this.logMsg = res.result;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.btnLoading = false;
                this.loading = false;
            })
        },

        timeChange(val) {
            this.form.startTime = val ? val[0] : '';
            this.form.endTime = val ? val[1] : '';
            this.getMsg(1);
        },

        setHeight() {
            this.tableHeight = window.innerHeight - 310;
        },

        handleCurrentChange(page) {
            this.page = page;
            this.getMsg();
        }

    }

}
</script>

<style scoped>
.el-select {
    width: 200px;
}

/deep/ .el-form--inline .el-form-item {
    margin-right: 16px!important;
}
</style>
